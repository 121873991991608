import React from "react";

const Privacy = () => {
  return (
    <div style={{ padding: "20px" }}>
      <h1>Privacy Policy (subleaseit.online)</h1>
      <p>Effective Date: 10 January, 2025 </p>
      <p>
        This Privacy Policy explains how we collect, use, and protect your
        personal information when you use our website subleaseit.online. By using the
        Service, you agree to this policy.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        - <strong>Personal Information:</strong> When you register or contact
        others through the Service, we may collect your name, email address, and
        other contact details.
        <br />- <strong>Usage Data:</strong> We may collect information about
        your interactions with the Service, such as IP addresses, browser type,
        and activity logs.
      </p>

      <h2>2. How We Use Your Information</h2>
      <p>
        - To provide and improve the Service.
        <br />
        - To communicate with you regarding your account or advertisements.
        <br />- To monitor and enforce compliance with our Terms of Service.
      </p>

      <h2>3. Sharing Your Information</h2>
      <p>
        We do not sell or rent your personal information. We may share
        information:
        <br />
        - With third-party service providers to operate the Service.
        <br />- When required by law or to protect our rights or safety.
      </p>

      <h2>4. Data Security</h2>
      <p>
        We take reasonable precautions to protect your personal information.
        However, no method of transmission or storage is completely secure, and
        we cannot guarantee absolute security.
      </p>

      <h2>5. Cookies</h2>
      <p>
        Our Service may use cookies to enhance user experience and analyze
        website traffic. You can adjust your browser settings to refuse cookies,
        but this may limit certain features of the Service.
      </p>

      <h2>6. Third-Party Links</h2>
      <p>
        Our Service may contain links to third-party websites. We are not
        responsible for the privacy practices or content of those sites.
      </p>

      <h2>7. Changes to this Policy</h2>
      <p>
        We may update this Privacy Policy periodically. Changes will be posted
        on this page, and continued use of the Service constitutes acceptance of
        the updated policy.
      </p>

      <h2>8. Contact Information</h2>
      <p>
        For questions about this Privacy Policy, please contact us at subleaseit.help@gmail.com.
      </p>
    </div>
  );
};

export default Privacy;
