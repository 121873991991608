import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import NavScrollExample from "../components/Navbar";
import axios from "axios";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useEffect, useState, useRef } from "react";
import FormData, { setMaxListeners } from "form-data";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../styles/adpost.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faHandPointLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
// import { REACT_APP_BASE_URL } from "process.env";
import Footer from "../components/footer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill's CSS
import lottie from "lottie-web";
// import mixpanel from "../components/mixpanelInit";
import PhoneInput from "react-phone-number-input";
// import { isValidPhoneNumber } from 'react-phone-number-input'
import isValidPhoneNumber from "libphonenumber-js";
import heic2any from "heic2any";
import Dropdown from "react-bootstrap/Dropdown";
import { useParams } from "react-router-dom";

import "react-phone-number-input/style.css";
import { adminEmails } from "../utils/utils";

function MyPostEdit() {
  const isAdmin = adminEmails.includes(
    JSON.parse(localStorage.getItem("tokenDecoded"))?.email
  );
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [type, setType] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [filesCount, setFilesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const animationContainerRef = useRef(null);
  const [animationInstance, setAnimationInstance] = useState(null);
  const [isAnimationVisible, setIsAnimationVisible] = useState(false); // Add a state to control animation visibility
  const [streetSplit, setStreetSplit] = useState("");
  const [showImageField, setShowImageField] = useState(false);

  const [gender, setGender] = useState("");
  const [diet, setDiet] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [subPost, setSubPost] = useState({});

  const [arrayToShow, setArrayToShow] = useState([
    "Analyzing images",
    "Verifying extensions",
    "Compressing images",
    "Converting images",
    "Uploading to cloud",
    "Verifying upload",
    "Almost done",
    "Finishing up",
    "Hang on",
    "Please wait",
    "Sit tight",
    "Taking longer",
    "Almost there",
    "Be Patient",
    "Hang on",
    "Please wait",
    "Sit tight",
    "Taking longer",
    "Almost there",
    "Be Patient",
  ]);

  const [whatsappImgIndex, setWhatsappImgIndex] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [
    isWhatsappSharingAllowedForAdmin,
    setisWhatsappSharingAllowedForAdmin,
  ] = useState(false);
  const fileInputRef = useRef(null);
  const [is_lease_or_sublease, setis_lease_or_sublease] = useState("");
  const [rent_for_whole_period, setrent_for_whole_period] = useState("month");
  const [furnished, setFurnished] = useState("");

  const privateRadioRef = useRef(null);
  const sharedRadioRef = useRef(null);
  // Function to handle tab click
  const handleTabClick = (tab) => {
    setis_lease_or_sublease(tab);
    setIsDisabled(false);
  };
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    //
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % arrayToShow.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [arrayToShow]);

  useEffect(() => {
    // Simulate loading for 3 seconds
    // console.log("eeeee", whatsappImgIndex);
    if (is_lease_or_sublease === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }

    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(loadingTimeout);
  }, []);

  const navigate = useNavigate();

  const clearStorageAndRedirectToLandingPage = (errorMsg) => {
    localStorage.clear();
    navigate("/");
    toast.error(errorMsg);
    setIsFormSubmitting(false);
  };

  const logoutIfUserNotLoggedIn = () => {
    // if token exists, redirect
    let decodedToken = localStorage.getItem("tokenDecoded");
    if (!decodedToken)
      return clearStorageAndRedirectToLandingPage("Login to post Ad");
    decodedToken = JSON.parse(decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 <= currentDate.getTime())
      clearStorageAndRedirectToLandingPage(
        "Session expired, please login to post Ad"
      );
  };

  useEffect(() => {
    logoutIfUserNotLoggedIn();
  }, []);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleDeleteImage = (index) => {
    // console.log("whatsappImgIndex before DElete", whatsappImgIndex);

    // Check if the deleted image was the selected one
    if (whatsappImgIndex === index) {
      setWhatsappImgIndex(null); // Reset the selection
      // console.log("whatsappImgIndex after DElete", whatsappImgIndex);
    } else if (whatsappImgIndex > index) {
      // If the deleted image was before the selected one, then decrement the selected image index by 1
      var whatsappImgIndex1 = whatsappImgIndex - 1;
      setWhatsappImgIndex(whatsappImgIndex1);
      // console.log(
      //   "whatsappImgIndex after DElete second else",
      //   whatsappImgIndex1
      // );
      const radioButtons = document.querySelectorAll('input[type="radio"]');
      radioButtons.forEach((radioButton) => {
        if (radioButton.value === whatsappImgIndex1.toString()) {
          radioButton.checked = true;
        }
      });
    } else {
      // If the deleted image was after the selected one, then do nothing
    }
    if (selectedFiles.length === 1) {
      fileInputRef.current.value = "";
    }

    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
    setFilesCount(filesCount - 1);
  };

  const fileArray = [];

  const handleFileChange = async (e) => {
    const files = e.target.files;

    if (files.length > 5) {
      toast.error("You can upload a maximum of 5 images.");
      setCurrentIndex(0);
      setIsFormSubmitting(false);

      e.target.value = ""; // Clear the file input value
      return;
    }
    if (files.length == 1) {
      setWhatsappImgIndex(0);
      // console.log("GERGEGVEG");
      // console.log("whatsappImgIndex FILES", whatsappImgIndex);
    }
    //check the file size of each file and if it is more than 15 mb, then show an error message and return from the function
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 15000000) {
        setCurrentIndex(0);
        toast.error(
          "Max image size allowed is 15 mb. (Don't choose big or RAW images)"
        );
        setCurrentIndex(0);
        setIsFormSubmitting(false);
        setIsImageUploading(false);

        e.target.value = ""; // Clear the file input value
        return;
      }
    }
    setIsImageUploading(true);

    const allowedExtensions = ["jpg", "jpeg", "png", "gif", "heic"];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const fileNameParts = file.name.split(".");
      const fileExtension =
        fileNameParts[fileNameParts.length - 1].toLowerCase();

      // const uniqueId = Date.now() + i;
      // const imageObject = {
      //   id: uniqueId,
      //   file: file,
      // };

      if (!allowedExtensions.includes(fileExtension)) {
        setCurrentIndex(0);
        setIsFormSubmitting(false);
        setIsImageUploading(false);

        toast.error("Only JPG, JPEG, PNG, HEIC and GIF files are allowed");

        e.target.value = "";
        return;
      }
      // Convert HEIC to JPEG if it is a HEIC file
      if (fileExtension === "heic") {
        //
        try {
          setIsFormSubmitting(true);
          const convertedBlob = await heic2any({
            blob: file,
            to: "image/jpeg",
          });
          const convertedFile = new File([convertedBlob], file.name, {
            type: "image/jpeg",
          });

          // fileArray.push({
          //   id: uniqueId,
          //   file: convertedFile,
          // });
          fileArray.push(convertedFile);
        } catch (error) {
          setCurrentIndex(0);
          toast.error("Error uploading HEIC image.");
          setIsFormSubmitting(false);
          setIsImageUploading(false);

          // console.error("Error converting HEIC to JPEG:", error);
        }
      } else {
        // for (let i = 0; i < files.length; i++) {
        // fileArray.push(imageObject);
        fileArray.push(file);
      }
    }

    setIsFormSubmitting(false);
    setIsImageUploading(false);
    setSelectedFiles((prevFiles) => [...prevFiles, ...fileArray]);
    setFilesCount(filesCount + files.length);

    // console.log("Selected files:", selectedFiles);
  };
  //
  //
  useEffect(() => {
    const radioButtons = document.querySelectorAll('input[type="radio"]');
    radioButtons.forEach((radioButton) => {
      // console.log("radioButton.id USEFFECT", radioButton.id);
      // console.log("whatsappImgIndex USEFFECT", whatsappImgIndex);
      if (radioButton.id == "socialImageShare-0") {
        radioButton.checked = true;
      } else {
        radioButton.checked = false;
      }
    });
  }, [selectedFiles.length == 1]);
  var city = "";
  var state = "";
  var zipcode1 = "";
  var street = "";
  const handleTypeChange = (e) => {
    setType(e.target.value);
  };
  const handleImageFieldToggle = () => {
    setShowImageField(!showImageField);
  };
  const handlePlusClick = (e) => {
    document.querySelector('input[type="file"]').click(); // Click on the checkbox
  };

  // setIsPrivate(type === "Private" ? true : false);
  const currentDate = new Date().toISOString().split("T")[0]; // Get current date in the format "YYYY-MM-DD"

  const handleSocialImageShareChange = (index) => {
    // console.log("e", e.target.value);
    // console.log("index", index);

    //check the corresponding radio button when an image is selected for whatsapp share
    const radioButtons = document.querySelectorAll('input[type="radio"]');
    radioButtons.forEach((radioButton) => {
      if (radioButton.value === index.toString()) {
        radioButton.checked = true;
      } else {
        radioButton.checked = false;
      }
    });
    setWhatsappImgIndex(index);
  };

  const { id } = useParams();
  async function getSubleasePost() {
    try {
      const res = await axios.get(baseUrl + "/adpost/" + id, {
        withCredentials: true,
      });
      //

      const sub = (await res) && res.data;
      //
      setSubPost(sub);
      const streetTemp = (await sub) && sub.street;
      // console.log("subpost", sub);
      const streetSplitTemp = (await streetTemp) && streetTemp.split(",");
      setStreetSplit(streetSplitTemp);

      if (sub.type === "Private") {
        privateRadioRef.current.setAttribute("checked", true);
        setType("Private");
      } else {
        sharedRadioRef.current.setAttribute("checked", true);
        setType("Shared");
      }
      // console.log("subpost.type ", type);
      setGender((await sub) && sub.gender);
      setDiet((await sub) && sub.diet);
      setis_lease_or_sublease((await sub) && sub.is_lease_or_sublease);
      setEditorContent((await sub) && sub.notes);
      // editGender((await sub) && sub.gender);
      // editDiet((await sub) && sub.diet);
    } catch (err) {
      setCurrentIndex(0);
      //
    } finally {
      // setSpinnerActive(false);
    }
  }
  street = streetSplit[0];
  city = streetSplit[1];
  state = streetSplit[2];
  zipcode1 = streetSplit[3];
  // console.log("ZZZ", zipcode1);
  //
  useEffect(() => {
    getSubleasePost();
    // console.log("subPost", subPost);
  }, []);

  useEffect(() => {
    setisWhatsappSharingAllowedForAdmin(
      subPost.isWhatsappSharingAllowedForAdmin
    );
  }, [subPost]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //
    setCurrentIndex(0);
    setIsFormSubmitting(true);
    const street = e.target.elements.formBasic1.value;
    const city = e.target.elements.formBasic2.value;
    const state = e.target.elements.formBasic3.value;
    const zipcode = e.target.elements.formBasic4.value;
    const typeSelected = type;
    const appt_no = e.target.elements.formBasic12.value;
    var shared_with;
    if (type === "Shared") {
      shared_with = e.target.elements.formBasic14.value;
    } else {
      shared_with = 0;
    }
    const tot_ppl_in_appt = e.target.elements.formBasic15.value;
    const no_of_bed = e.target.elements.formBasic16.value;
    const no_of_bath = e.target.elements.formBasic17.value;
    const sublease_start_date = e.target.elements.formBasic18.value;
    const sublease_end_date = e.target.elements.formBasic19.value;
    const rent = e.target.elements.formBasic11.value;
    var searching_no_of_ppl;
    if (is_lease_or_sublease === "lease") {
      searching_no_of_ppl = e.target.elements.searching_no_of_ppl.value;
    } else {
      searching_no_of_ppl = -1;
    }
    const isWhatAppNumber = isChecked;

    //
    // if (whatsappImgIndex === null) {
    //   // console.log("whatsappImageId", whatsappImageId);
    //   toast.error(
    //     "Please select a image to share on whatsapp with your lease/sublease ad."
    //   );
    //   setIsFormSubmitting(false);
    //   return;
    // }
    if (
      !street ||
      !city ||
      !state ||
      !zipcode ||
      !appt_no ||
      !sublease_start_date ||
      !sublease_end_date ||
      !rent ||
      !gender ||
      !diet
    ) {
      setCurrentIndex(0);
      toast.error("Please fill in all required fields.");
      setIsFormSubmitting(false);
      return;
    }

    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("street", street);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("zipcode", zipcode);
    formData.append("type", typeSelected);
    formData.append("appt_no", appt_no);
    formData.append("shared_with", shared_with);
    formData.append("tot_ppl_in_appt", tot_ppl_in_appt);
    formData.append("no_of_bed", no_of_bed);
    formData.append("no_of_bath", no_of_bath);
    formData.append("sublease_start_date", sublease_start_date);
    formData.append("sublease_end_date", sublease_end_date);
    formData.append("rent", rent);
    formData.append("notes", editorContent);
    formData.append("phone", phoneNumber);
    formData.append("isWhatsappNumber", isWhatAppNumber);
    formData.append("gender", gender);
    formData.append("diet", diet);
    formData.append("whatsappImgIndex", whatsappImgIndex);
    formData.append(
      "isWhatsappSharingAllowedForAdmin",
      isWhatsappSharingAllowedForAdmin
    );
    formData.append("is_lease_or_sublease", is_lease_or_sublease);
    formData.append("searching_no_of_ppl", searching_no_of_ppl);
    formData.append("rent_for_whole_period", rent_for_whole_period);
    formData.append("furnished", furnished);

    for (let i = 0; i < selectedFiles.length; i++) {
      // console.log("selectedFiles[i]", selectedFiles[i]);
      formData.append("imageUrls", selectedFiles[i]);
    }

    // mixpanel.track("New Post Try", formData);

    setIsLoading(true);

    axios
      .post(baseUrl + "/adpost/" + id + "/edit", formData, {
        headers: {
          // set content type for images to be sent
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        //
        //
        navigate("/subleaseposts/" + id);
        toast.success("Ad Changed Successfully!");
        // mixpanel.track("post edited", formData);
        setIsLoading(false);
      })
      .catch((err) => {
        //
        //
        if (err.response && err.response.status === 404) {
          setCurrentIndex(0);
          setIsFormSubmitting(false);
          toast.error("Session expired. Please login again.");
          return;
        }
        setCurrentIndex(0);
        setIsFormSubmitting(false);
        // toast.error("Oops! Something went wrong.");
        setIsLoading(false);

        // mixpanel.track("post edit failed", formData);
        // toast.error("Oops! Something went wrong.");
      });
  };

  const [zipcode, setZipcode] = useState("");

  const handlezipChange = (e) => {
    // Add a check to allow only 5 digits for zipcode and show a message to the user that max 5 digits are allowed for zipcode. If the user enters more than 5 digits and a hyphen, then allow up to four more digits after the hyphen.
    var zip = e.target.value;
    if (zip.length > 5 && zip[5] !== "-") {
      setCurrentIndex(0);
      toast.error("Max 5 digits are allowed for zipcode");
      setIsFormSubmitting(false);

      return;
    } else if (zip.length > 6 && zip[5] === "-" && zip.length > 11) {
      setCurrentIndex(0);
      toast.error("Max 10 digits are allowed for phone number");
      setIsFormSubmitting(false);

      return;
    } else {
      //
      setZipcode(e.target.value);
    }
  };

  const handlePhoneNumberChange = (value) => {
    // Convert the value to a string to ensure it's always a string
    const stringValue = value ?? "";

    //CHECK IF THE PHONE NUMBER LENGTH IS MORE LESS THAN 15 DIGITS OR NOT
    if (stringValue.length > 16) {
      setCurrentIndex(0);
      toast.error("Max 16 digits are allowed for phone number");
      setIsFormSubmitting(false);
      return;
    }
    // If the phone number is possible, update the state
    setPhoneNumber(stringValue);
  };

  return (
    <>
      <NavScrollExample />
      <div className="App ">
        {/* {!isAnimationVisible && ( */}
        <div className="row d-flex justify-content-center mx-2">
          <div
            className="card adpostmob"
            style={{
              padding: "56px",
              border: "none",
              // marginTop: "10px",
              // marginBottom: "15px",
            }}
          >
            <div
              className=" card header"
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                paddingBottom: "10px",
                backgroundImage: "linear-gradient(to right, #520821 , #C12D22)",
              }}
            >
              <h4
                style={{
                  textAlign: "start",
                  color: "whitesmoke",
                  paddingTop: "10px",
                  paddingLeft: "10px",
                }}
              >
                {subPost.is_lease_or_sublease === "lease"
                  ? "Lease "
                  : "Sublease "}
                Apartment Details
              </h4>
            </div>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <div className="row">
                <div className="col-md-6">
                  <h1>Step 1</h1>

                  <div className="mt-4">
                    {/* <div className="col-md-6"> */}
                    <Row className="mb-3 mt-3">
                      <Form.Group
                  // as={Col}
                  // className="mb-4"
                  // controlId="formBasic12"
                  >
                    {/* <div as={Col} className="mb-4"> */}
                    <Form.Label>
                      Is this a Sublease or Lease?
                      <span className="required"> * </span>
                    </Form.Label>
                    {/* </div> */}
                    <div>
                      <Button
                        className="ml-4 mr-4"
                        variant={
                          is_lease_or_sublease === "lease"
                            ? "dark"
                            : "outline-dark"
                        }
                        style={{ marginRight: "10px", marginLeft: "10px" }}
                        onClick={() => handleTabClick("lease")}
                      >
                        Lease
                      </Button>

                      {/* Button for Tab 2 */}
                      <Button
                        variant={
                          is_lease_or_sublease === "sublease"
                            ? "dark"
                            : "outline-dark"
                        }
                        onClick={() => handleTabClick("sublease")}
                      >
                        Sublease
                      </Button>
                    </div>
                  </Form.Group>
                      <Form.Group
                        as={Col}
                        className="mb-4 "
                        controlId="formBasic12"
                      >
                        <Form.Label>
                          Appartment No.
                          <span className="required"> * </span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="E.g. 27"
                          required
                          defaultValue={subPost.appt_no}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        className="mb-4"
                        controlId="formBasic1"
                      >
                        <Form.Label>
                          Street Name <span className="required"> * </span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="1234 Ellendale Pl"
                          defaultValue={street}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formBasic2"
                      >
                        <Form.Label>
                          City <span className="required"> * </span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Los Angeles"
                          defaultValue={city}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formBasic3"
                      >
                        <Form.Label>
                          State <span className="required"> * </span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="California"
                          defaultValue={state}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formBasic4"
                      >
                        <Form.Label>
                          Zipcode<span className="required"> * </span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          // onChange={handlezipChange}
                          placeholder="90007"
                          defaultValue={zipcode1}
                        />
                      </Form.Group>
                    </Row>
                    <hr></hr>
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formBasic18"
                      >
                        <Form.Label>
                          {subPost.is_lease_or_sublease === "lease"
                            ? "Lease"
                            : "Sublease"}{" "}
                          Start Date
                          <span className="required"> * </span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="15"
                          required
                          defaultValue={subPost.sublease_start_date}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formBasic19"
                      >
                        <Form.Label>
                          {subPost.is_lease_or_sublease === "lease"
                            ? "Lease"
                            : "Sublease"}{" "}
                          End Date
                          <span className="required"> * </span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder=""
                          required
                          min={currentDate}
                          defaultValue={subPost.sublease_end_date}
                        />
                      </Form.Group>
                    </Row>
                    {is_lease_or_sublease === "lease" ? (
                      <Form.Group className="mb-3" controlId="formBasic11">
                        <Form.Label>
                          Rent($) per month
                          <span className="required"> * </span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="E.g. 1200"
                          required
                          defaultValue={subPost.rent}
                        />
                      </Form.Group>
                    ) : (
                      <Row className="mb-3">
                        {subPost.is_lease_or_sublease === "sublease" && (
                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlId="formBasic11"
                            xs="auto"
                          >
                            <Form.Label>
                              Rent period
                              <span className="required"> * </span>
                            </Form.Label>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="outline-dark"
                                id="dropdown-basic"
                              >
                                {rent_for_whole_period === "month" ? (
                                  <span>month</span>
                                ) : (
                                  <span>whole duration</span>
                                )}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    setrent_for_whole_period("month")
                                  }
                                  href="#/action-1"
                                >
                                  month
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    setrent_for_whole_period(
                                      "complete sublease period"
                                    )
                                  }
                                  href="#/action-2"
                                >
                                  whole sublease duration
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Form.Group>
                        )}
                        <Form.Group
                          as={Col}
                          className="mb-3"
                          controlId="formBasic11"
                          xs={9}
                        >
                          <Form.Label>
                            Rent($)
                            <span className="required"> * </span>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            placeholder="E.g. 1200"
                            required
                            defaultValue={subPost.rent}
                          />
                        </Form.Group>
                      </Row>
                    )}
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formBasic16"
                      >
                        <Form.Label>
                          No of bedrooms
                          <span className="required"> * </span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="E.g. 2"
                          required
                          defaultValue={subPost.no_of_bed}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formBasic17"
                      >
                        <Form.Label>
                          No of bathrooms
                          <span className="required"> * </span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="E.g. 1"
                          required
                          defaultValue={subPost.no_of_bath}
                        />
                      </Form.Group>
                    </Row>
                  </div>
                </div>
                <div className="col-md-6">
                  <h1>Step 2</h1>
                  <div className="mt-4">
                    <Form.Group className="mb-3" controlId="formBasic13">
                      <Form.Label>
                        Sharing Type
                        <span className="required"> * </span>
                      </Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          label="Private"
                          name="sharingType"
                          id="privateRadio"
                          value="Private"
                          onChange={handleTypeChange}
                          ref={privateRadioRef}
                          checked={type === "Private"}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="Shared"
                          name="sharingType"
                          id="sharedRadio"
                          value="Shared"
                          onChange={handleTypeChange}
                          ref={sharedRadioRef}
                          checked={type === "Shared"}
                        />
                      </div>
                    </Form.Group>
                    {type === "Shared" ? (
                      <Form.Group className="mb-3" controlId="formBasic14">
                        <Form.Label>
                          No. of people to share room with
                        </Form.Label>
                        <span className="required"> * </span>
                        <Form.Control
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="E.g. 2"
                          required
                          defaultValue={subPost.shared_with}
                        />
                      </Form.Group>
                    ) : null}

                    <Form.Group className="mb-3" controlId="formBasic15">
                      <Form.Label>
                        Total no. of people in apartment <b>(Optional)</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        placeholder="E.g. 4"
                        defaultValue={subPost.tot_ppl_in_appt}
                      />
                    </Form.Group>
                    {is_lease_or_sublease === "lease" && (
                      <Form.Group
                        className="mb-3"
                        controlId="searching_no_of_ppl"
                      >
                        <Form.Label>
                          Number of people you are looking to lease to{" "}
                        </Form.Label>
                        <span className="required"> * </span>

                        <Form.Control
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="E.g. 2"
                          required
                          defaultValue={subPost.searching_no_of_ppl}
                        />
                      </Form.Group>
                    )}
                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="formBasic11"
                      xs="auto"
                    >
                      <Form.Label>
                        Is this apartment furnished?
                        <span className="required"> * </span>
                      </Form.Label>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="outline-dark"
                          id="dropdown-basic"
                          defaultValue={subPost.furnished}
                        >
                          {furnished === "furnished" ? (
                            <span>Fully Furnished</span>
                          ) : furnished === "partially" ? (
                            <span>Partially Furnished</span>
                          ) : (
                            <span>Not Furnished</span>
                          )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => setFurnished("furnished")}
                            href="#/action-1"
                          >
                            Fully Furnished
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setFurnished("partially")}
                            href="#/action-2"
                          >
                            Partially Furnished
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setFurnished("unfurnished")}
                            href="#/action-2"
                          >
                            Not Furnished
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="gender">
                      <Form.Label>Gender Preference</Form.Label>
                      <span className="required"> * </span>
                      <div>
                        <Form.Check
                          inline
                          label="Male"
                          name="gender"
                          type="radio"
                          value="Male"
                          id="inline-gender-1"
                          checked={gender === "Male"}
                          onClick={(e) => {
                            setGender(e.target.value);
                          }}
                        />
                        <Form.Check
                          inline
                          label="Female"
                          name="gender"
                          type="radio"
                          value="Female"
                          id="inline-gender-2"
                          checked={gender === "Female"}
                          onClick={(e) => {
                            setGender(e.target.value);
                          }}
                        />
                        <Form.Check
                          inline
                          label="Others"
                          name="gender"
                          type="radio"
                          value="Others"
                          id="inline-gender-3"
                          checked={gender === "Others"}
                          onClick={(e) => {
                            setGender(e.target.value);
                          }}
                        />
                        <Form.Check
                          inline
                          label="No Preference"
                          name="gender"
                          type="radio"
                          value="No Preference"
                          id="inline-gender-4"
                          checked={gender === "No Preference"}
                          onClick={(e) => {
                            setGender(e.target.value);
                          }}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="diet">
                      <Form.Label>Diet Preference</Form.Label>
                      <span className="required"> * </span>
                      <div>
                        <Form.Check
                          inline
                          label="Jain"
                          name="diet"
                          type="radio"
                          value="Jain"
                          id="inline-diet-1"
                          checked={diet === "Jain"}
                          onClick={(e) => {
                            setDiet(e.target.value);
                          }}
                        />
                        <Form.Check
                          inline
                          label="Veg"
                          name="diet"
                          type="radio"
                          id="inline-diet-2"
                          value="Veg"
                          checked={diet === "Veg"}
                          onClick={(e) => {
                            setDiet(e.target.value);
                          }}
                        />
                        <Form.Check
                          inline
                          label="Non-Veg"
                          name="diet"
                          type="radio"
                          value="Non-Veg"
                          id="inline-diet-3"
                          checked={diet === "Non-Veg"}
                          onClick={(e) => {
                            setDiet(e.target.value);
                          }}
                        />
                        <Form.Check
                          inline
                          label="No Preference"
                          name="diet"
                          type="radio"
                          id="inline-diet-4"
                          value="No Preference"
                          checked={diet === "No Preference"}
                          onClick={(e) => {
                            setDiet(e.target.value);
                          }}
                        />
                      </div>
                    </Form.Group>

                    <div className="mb-2">Existing Images</div>
                    <Row
                      style={{
                        backgroundColor: "aliceblue",
                        borderRadius: 10,
                        marginBottom: "5px",
                      }}
                    >
                      {subPost.imageUrls &&
                        JSON.parse(subPost.imageUrls) &&
                        JSON.parse(subPost.imageUrls).map((image, index) => (
                          <Col sm={4} md={6} lg={4} key={index}>
                            {process.env.REACT_APP_ENV === "development" ? (
                              <img
                                className="d-block w-100 carousel-image"
                                src={`${image}`}
                                style={{
                                  borderRadius: 10,
                                  width: "80%",
                                  height: "auto",
                                  marginTop: "15px",
                                  // marginRight: "5px",
                                  marginBottom: "15px",
                                }}
                                alt="Apartment images"
                              />
                            ) : (
                              <img
                                style={{
                                  borderRadius: 10,
                                  width: "80%",
                                  height: "auto",
                                  marginTop: "15px",
                                  // marginRight: "5px",
                                  marginBottom: "15px",
                                }}
                                className="d-block w-100 carousel-image"
                                src={
                                  baseUrl +
                                  `/${image
                                    .replace("static/", "public/")
                                    .replace(/\\/g, "/")}`
                                }
                                alt="Apartment images"
                              />
                            )}
                          </Col>
                        ))}
                    </Row>

                    <Button
                      variant="outline-dark mt-1 mb-3 small"
                      onClick={handleImageFieldToggle}
                      size="sm"
                    >
                      Replace Existing images?
                    </Button>
                    {showImageField && (
                      <Form.Group className="mb-3" controlId="formBasic20">
                        <Form.Label>
                          Upload Images<span className="required"> * </span>{" "}
                          <span className="text-muted">
                            (Max 5 photos upto 15 mb each)
                          </span>
                          <br />
                        </Form.Label>
                        <div>
                          <Form.Control
                            type="file"
                            name="images"
                            multiple
                            onChange={handleFileChange}
                            style={{ marginBottom: "10px" }}
                            ref={fileInputRef}
                          />
                          {filesCount > 0 && (
                            <span>
                              {filesCount}{" "}
                              {filesCount > 1 ? (
                                <span>images selected.</span>
                              ) : (
                                <span>image selected.</span>
                              )}
                            </span>
                          )}
                        </div>
                        {/* <div style={{ overflowX: "auto" }}> */}

                        <Row
                          style={{
                            backgroundColor: "aliceblue",
                            borderRadius: 10,
                            marginBottom: "5px",
                            overflowX: "auto",
                            marginTop: "10px",
                            // padding: "13px 0px",
                          }}
                        >
                          {/* <div
                            className="d-flex"
                            style={{
                              flexWrap: "nowrap", // Prevent flex items from wrapping to next line
                            }}
                          > */}
                          {isImageUploading ? (
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                backgroundColor: "rgba(255, 255, 255, 0.5)",
                              }}
                            >
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                              />
                              Uploading...
                            </div>
                          ) : (
                            <br />
                          )}
                          {selectedFiles.length > 0 ? (
                            <p
                              style={{
                                color: "blueviolet",
                                paddingTop: "13px",
                              }}
                            >
                              Choose one main image - it will pop up when sharing your listing.{" "}
                              <span style={{ color: "red" }}>*</span>
                            </p>
                          ) : null}

                          {selectedFiles.map((image, index, length) => (
                            <>
                              <Col
                                sm={4}
                                md={6}
                                lg={4}
                                key={index}
                                style={{ position: "relative" }}
                              >
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "200px",
                                    overflow: "hidden",
                                  }}
                                  onClick={() =>
                                    handleSocialImageShareChange(index)
                                  }
                                >
                                  <img
                                    src={URL.createObjectURL(image)}
                                    alt=""
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "15px",
                                    }}
                                    //give index to the image
                                    onClick={() =>
                                      handleSocialImageShareChange(index)
                                    }
                                  />
                                  {/* {subPost.imageUrls &&
                                  JSON.parse(subPost.imageUrls) &&
                                  JSON.parse(subPost.imageUrls).map(
                                    (image, index) => (
                                      // <Col sm={4} md={6} lg={4} key={index}>
                                      <img
                                        src={`${image}`}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                          borderRadius: "15px",
                                        }}
                                        //give index to the image
                                        onClick={() =>
                                          handleSocialImageShareChange(index)
                                        }
                                      />
                                      // </Col>
                                    )
                                  )} */}
                                </div>
                                <button
                                  type="button"
                                  className="btn position-absolute"
                                  style={{
                                    position: "absolute",
                                    top: "-24px",
                                    right: "-16px",
                                  }}
                                  onClick={() => handleDeleteImage(index)}
                                >
                                  <i
                                    className="fa fa-times-circle"
                                    style={{
                                      fontSize: "35px",
                                      color: "red",
                                      backgroundColor: "white",
                                      borderRadius: "60%",
                                      padding: "0px",
                                    }}
                                  ></i>
                                </button>
                                <div
                                  style={{
                                    color: "blueviolet",
                                    padding: "13px 0px",
                                  }}
                                >
                                  <Form.Check
                                    inline
                                    type="radio"
                                    label="Choose for Sharing on WhatsApp*"
                                    name="socialImageShare"
                                    id={`socialImageShare-${index}`}
                                    value={index}
                                    onClick={() =>
                                      handleSocialImageShareChange(index)
                                    }
                                  />
                                </div>
                              </Col>
                            </>
                          ))}
                          {/* </div> */}
                        </Row>
                        {/* </div> */}

                        {filesCount >= 1 ? (
                          <i
                            className="bi bi-plus-square"
                            style={{
                              fontSize: "45px",
                              // color: "red",
                            }}
                            onClick={handlePlusClick}
                          ></i>
                        ) : null}
                      </Form.Group>
                    )}
                    {/* <Form.Group className="mb-3" controlId="formBasic10"> */}
                    <div>
                      <Form.Label>
                        Additional Notes <b>(Optional)</b>
                      </Form.Label>
                      {/* <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="E.g. Discuss ameneties, attractions, selling point"
                      />
                    </Form.Group> */}
                      <ReactQuill
                        value={editorContent}
                        onChange={setEditorContent}
                        placeholder="E.g. Discuss ameneties, attractions, selling point"
                        className="custom-editor"
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                            ["bold", "italic", "underline", "strike"],
                            [{ list: "ordered" }, { list: "bullet" }],
                            ["link"],
                            ["clean"],
                          ],
                        }}
                        formats={[
                          "header",
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "list",
                          "bullet",
                          "link",
                        ]}
                      />
                      <Form.Check
                        inline
                        type="checkbox"
                        className="pt-3"
                        label="Do you want us to share your posting on different social media platforms for you?"
                        name="checkboxforisWhatsappSharingAllowedForAdmin"
                        id="checkboxforisWhatsappSharingAllowedForAdmin"
                        value="false"
                        checked={isWhatsappSharingAllowedForAdmin}
                        onClick={() => {
                          setisWhatsappSharingAllowedForAdmin(
                            !isWhatsappSharingAllowedForAdmin
                          );
                        }}
                      />
                    </div>
                    <Form.Group as={Row} className="mb-3 pt-2">
                      <Col sm={{ span: 0, offset: 9 }}>
                        <Button
                          variant="secondary"
                          disabled={isFormSubmitting}
                          type="submit"
                          size="lg"
                          style={{
                            // borderRadius: "15px 15px",
                            borderStyle: "none",
                            marginTop: "20px",
                            backgroundImage:
                              "linear-gradient(to right, #520821 , #C12D22)",
                            // fontSize: "20px",
                            // marginLeft: "250px",
                          }}
                        >
                          {isLoading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                              />
                              {arrayToShow[currentIndex]}
                            </>
                          ) : (
                            // Show the button label when not loading
                            "Update Post"
                          )}
                        </Button>
                      </Col>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
        {/* )} */}
        {/* <div
          className="mr-2"
          ref={animationContainerRef}
          id="animationContainer"
          style={{ display: isAnimationVisible ? "block" : "none" }} // Hide the animation initially
        ></div> */}
        <Footer></Footer>
      </div>
    </>
  );
}

export default MyPostEdit;
