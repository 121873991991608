import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faInstagram,
  faMeta,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import "../styles/components/footer.css";

function Footer(props) {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container" id="contact">
          <div className="row justify-content-start justify-content-md-between">
            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>Contact Us</h3>
              <p>
                <strong>Email:</strong> subleaseit.help@gmail.com
                <br />
              </p>
            </div>

            <div className="col-lg-3 col-md-6 footer-social-media">
              {/* Add your code for social media icons */}
              <h3>SOCIAL MEDIA</h3>
              <p className="social-icons">
                <a
                  href="https://www.instagram.com/subleaseit/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    width={50}
                    size="2x"
                    style={{ color: "rgb(153, 0, 0)" }}
                  />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61572236484515"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faMeta}
                    width={50}
                    size="2x"
                    style={{ color: "rgb(153, 0, 0)" }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/subleaseit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    width={50}
                    size="2x"
                    style={{ color: "rgb(153, 0, 0)" }}
                  />
                </a>
              </p>
            </div>

            {/* <div className="col-lg-3 col-md-6 footer-links">
              <h3>IF NEED ONE MORE SECTION USE THIS</h3>
              <p>CONTENT HERE</p>
            </div> */}

            <div className="col-lg-3 col-md-6 footer-social-media">
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <h3 style={{ margin: 0 }}>About Us</h3>
                <a
                  href="https://subleaseit.online/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FontAwesomeIcon
                    icon={faLink}
                    width={50}
                    size="2x"
                    style={{ color: "rgb(153, 0, 0)" }}
                  />
                </a>
              </div>
              <p>
                Discover Lease/Sublease around USC - University of Southern
                California. Find safe property deals posted by university
                students.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#8B0000",
          color: "#fff",
          padding: "20px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {/* Note Section */}
          <p style={{ margin: 0, fontSize: "14px", textAlign: "center" }}>
            <strong>Note:</strong> While we exclusively serve University
            Students, exercise caution in all interactions.
          </p>

          {/* Copyright Section */}
          <p style={{ margin: 0, fontSize: "14px", textAlign: "center" }}>
            Copyright © 2025{" "}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/"
              style={{ color: "yellow", textDecoration: "none" }}
            >
              <strong>Sublease it</strong>
            </Link>{" "}
            | All Rights Reserved
          </p>

          {/* Terms & Privacy */}
          <p style={{ margin: 0, fontSize: "14px", textAlign: "center" }}>
            <Link
              to="/terms-of-service"
              style={{
                color: "yellow",
                textDecoration: "none",
                marginRight: "10px",
              }}
            >
              Terms of Service
            </Link>
            |
            <Link
              to="/privacy-policy"
              style={{
                color: "yellow",
                textDecoration: "none",
                marginLeft: "10px",
              }}
            >
              Privacy Policy
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
