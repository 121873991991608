import React from "react";

const TOC = () => {
  return (
    <div style={{ padding: "20px" }}>
      <h1>Terms of Service (subleaseit.online)</h1>
      <p>Effective Date: 10 January, 2025</p>
      <p>
        By accessing or using our website <i>subleaseit.online</i>, you agree to comply with
        and be bound by the following terms and conditions Terms of Service.
        If you do not agree, you may not use the Service.
      </p>

      <h2>1. Disclaimer</h2>
      <p>
        Our Service acts solely as an intermediary platform to help students and
        individuals post advertisements for subleasing and contact each other.
        We do not guarantee the accuracy, legality, or safety of any
        advertisement, transaction, or user interaction. We are not involved in
        any agreements or disputes between users.
      </p>

      <h2>2. Eligibility</h2>
      <p>
        The Service is intended primarily for students. By using the Service,
        you confirm that you are at least 18 years of age or older.
      </p>

      <h2>3. User Responsibilities</h2>
      <p>
        Users are responsible for providing accurate information in their
        advertisements and for complying with all applicable laws and
        regulations when posting or responding to listings. Users are solely
        responsible for interactions and transactions arising from the use of
        the Service.
      </p>

      <h2>4. Limitation of Liability</h2>
      <p>
        Under no circumstances will we be liable for any direct, indirect,
        incidental, special, consequential, or exemplary damages resulting from
        the use of the Service or any user interactions. This includes, but is
        not limited to, physical harm, financial loss, or fraudulent activity.
      </p>

      <h2>5. Content and Intellectual Property</h2>
      <p>
        Users retain ownership of the content they upload but grant us a
        non-exclusive, royalty-free, worldwide license to display, distribute,
        and promote it within the Service.
      </p>

      <h2>6. Termination</h2>
      <p>
        We reserve the right to suspend or terminate access to the Service at
        our discretion, without notice, for violations of these Terms or any
        applicable laws.
      </p>

      <h2>7. Changes to Terms</h2>
      <p>
        We may update these Terms from time to time. Continued use of the
        Service constitutes acceptance of any revised Terms.
      </p>

      <h2>8. Contact Information</h2>
      <p>
        For questions about these Terms, please contact us at subleaseit.help@gmail.com.
      </p>
    </div>
  );
};

export default TOC;
